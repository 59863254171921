<template>
  <v-dialog v-model="isShow" max-width="420px">
    <v-card class="custom">
      <v-card-title v-html="title"></v-card-title>
      <v-card-text v-html="message" style="color: #bdbdbd"></v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="cancel">{{
          $t("common.cancel")
        }}</v-btn>
        <v-btn class="pl-10 pr-10 ml-10" text @click="agree">{{
          $t("common.confirmed")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t("asset.confirm");
      },
    },
    message: {
      type: String,
      default: function () {
        return this.$t("asset.confirmMessage");
      },
    },
  },
  data() {
    return {
      isShow: false,
      resolve: null,
    };
  },
  methods: {
    confirm(title, message) {
      this.isShow = true;
      this.title = title || this.title;
      this.message = message || this.message;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    agree() {
      this.resolve(true);
      this.isShow = false;
    },
    cancel() {
      this.resolve(false);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  .v-card__text {
    font-size: 1rem;
  }
}
</style>
