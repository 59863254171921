<template>
  <div style="width: 100%">
    <h4 class="no_data mb-4" v-if="loc" style="opacity: 0">
      {{ loc.lat }} - {{ loc.long }}
    </h4>
    <ble-connect />
  </div>
</template>

<script>
import BleConnect from "@/components/BleConnect.vue";
import { Geolocation } from "@capacitor/geolocation";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    BleConnect,
  },
  computed: {
    ...mapGetters("Authen", ["getUser"]),
    ...mapGetters("Scanner", ["getScannedPhoneNumber"]),
  },
  watch: {
    async getScannedPhoneNumber(val) {
      if (!val) return;
      this.$store.commit("Scanner/SET_SCANNED_PHONE_NUMBER", null);
      await this.subscribeProfile(val);
      this.$router.push("/history");
    },
  },
  data() {
    return {
      welcome: {
        message: "",
        icon: "icons/trophy.png",
      },
      loading: false,
      loc: null,
    };
  },
  mounted() {
    this.renderWelcome();
    this.getCurrentPosition();
  },
  methods: {
    async subscribeProfile(phone) {
      if (!phone) return;
      if (!this.networkStatus) return;
      phone = this.formatPhoneNumber(phone);
      var patientObj = await this.$dbGet(`patients/${phone}`);
      if (!patientObj || !patientObj.id) {
        return alert(this.$t("error.cannotFindAccount"));
      }
      var { id, fullName } = patientObj;
      if (!phone || this.$phone == phone) {
        return alert(this.$t("error.cannotFindAccount"));
      }
      if (
        !confirm(`${this.$t("common.confirmConnect")} ${fullName} - ${phone}?`)
      ) {
        return;
      }
      await this.$dbSet(`users/${this.$uid}/patients/${phone}`, {
        fullName,
      });
      var subscription = await this.$dbGet(`subscribe/${id}/${this.$uid}`);
      if (subscription) return;
      subscription = await this.$dbGet(`patients/${this.$phone}`);
      await this.$dbSet(`subscribe/${id}/${this.$uid}`, {
        ...subscription,
        isSubscribed: false,
        isSeen: false,
      });
      this.notifyInviteSubscribe(this.$uid, id);
    },
    async notifyInviteSubscribe(inviterId, subscriberId) {
      if (!inviterId || !subscriberId) return;
      if (!this.networkStatus) return;
      var payload = { inviterId, subscriberId };
      var resp = await axios.post(
        "https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/notifyInviteSubscribe",
        payload
      );
      console.log(resp.data);
    },
    async renderWelcome() {
      this.welcome.message = "Have a nice day at work !";
    },
    async getCurrentPosition() {
      // if (!this.isAndroid) return;
      // var loc = await Geolocation.getCurrentPosition();
      // var { latitude, longitude } = loc && loc.coords;
      // this.loc = { lat: latitude, long: longitude };
    },
  },
};
</script>

<style scoped lang="scss">
@import "./home.scss";
</style>
