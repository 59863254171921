<template>
  <div>
    <v-row v-show="!isMeasuring">
      <v-col cols="12" justify="center" align="center">
        <div :style="style" ref="lavContainer"></div>
        <h5 v-if="!bleAvailable" class="no_data">
          {{ $t("common.bleNotAvailable") }}
        </h5>
        <div v-if="bleAvailable && isNative" class="d-flex">
          <v-spacer />
          <v-btn
            class="mt-1 custom pa-7 pl-6 pr-8"
            :loading="loading"
            @click="$store.commit('Scanner/OPEN_SCANNER')"
          >
            <v-icon class="mr-3" small>mdi-line-scan</v-icon>
            {{ $t("common.scanQrDevice") }}
          </v-btn>
          <v-btn
            class="custom pa-7 mt-1 elevation-1 ml-2"
            fab
            :loading="loading"
            @click="requestDevices"
            small
          >
            <v-icon>mdi-bluetooth</v-icon>
          </v-btn>
          <v-spacer />
        </div>
        <div v-if="bleAvailable && !isNative" class="d-flex">
          <v-spacer />
          <v-btn
            class="mt-1 custom pa-7 pl-6 pr-8"
            :loading="loading"
            @click="requestDevices"
          >
            <v-icon class="mr-3" small>mdi-bluetooth</v-icon>
            {{ $t("common.scanQrDevice") }}
          </v-btn>
          <v-btn
            class="custom pa-7 mt-1 elevation-1 ml-2"
            fab
            :loading="loading"
            @click="$store.commit('Scanner/OPEN_SCANNER')"
            small
          >
            <v-icon small>mdi-line-scan</v-icon>
          </v-btn>
          <v-spacer />
        </div>
        <div class="content_card mt-5">
          <h4 class="mt-1 mb-3 d-flex justify-start" style="color: #e0e0e0">
            {{ $t("home.device_list") }}
          </h4>
          <v-tabs v-model="tabs" fixed-tabs class="tab_navigation_drawer">
            <template v-slot:prev>1</template>
            <template v-slot:next>1</template>
            <v-tab href="#HOLTER"> {{ $t("home.holter") }} </v-tab>
            <v-tab href="#TBQ"> {{ $t("home.stograte_cabinet") }} </v-tab>
            <v-tab href="#MH"> {{ $t("home.autoclave") }} </v-tab>
          </v-tabs>
        </div>
        <div class="content_card mt-2">
          <h4 class="mt-1 mb-3 d-flex justify-start" style="color: #e0e0e0">
            {{ $t("home.device_connected") }}:
          </h4>
          <v-tabs-items v-model="tabs" touchless>
            <v-tab-item value="HOLTER">
              <div v-if="listOtherDevices.length > 0">
                <content-item
                  v-for="(item, index) in listOtherDevices"
                  :key="index"
                  :item="item"
                  @view="onDeviceClicked(item.deviceName)"
                >
                  <template v-slot:controller>
                    <v-list-item-icon class="pt-0 pr-1">
                      <v-progress-circular
                        :size="26"
                        :width="3"
                        indeterminate
                        color="white"
                        v-if="loading"
                      ></v-progress-circular>

                      <v-btn
                        small
                        color="#eeeeee"
                        class="service_button"
                        fab
                        elevation="0"
                        style="background-color: rgb(69, 78, 112)"
                        v-if="!loading"
                        @click="
                          $event.stopPropagation();
                          removeConnectedBleDevice(item.deviceName);
                        "
                      >
                        <v-icon>mdi-close </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </template>
                </content-item>
              </div>
              <div v-else style="color: #e0e0e0">No data</div>
            </v-tab-item>
            <v-tab-item value="TBQ">
              <div v-if="listTBQDevices.length > 0">
                <content-item
                  v-for="(item, index) in listTBQDevices"
                  :key="index"
                  :item="item"
                  @view="onDeviceClicked(item.deviceName)"
                >
                  <template v-slot:controller>
                    <v-list-item-icon class="pt-0 pr-1">
                      <v-icon
                        small
                        color="orange"
                        v-if="listTBQStatus[item.deviceId].isError"
                      >
                        mdi-alert
                      </v-icon>
                      <v-icon
                        large
                        :class="{
                          'red--text': !listTBQStatus[item.deviceId].isOnline,
                          'green--text': listTBQStatus[item.deviceId].isOnline,
                        }"
                      >
                        mdi-circle-medium
                      </v-icon>

                      <v-progress-circular
                        :size="26"
                        :width="3"
                        indeterminate
                        color="white"
                        v-if="loading"
                      ></v-progress-circular>
                      <v-btn
                        small
                        color="#eeeeee"
                        class="service_button"
                        fab
                        elevation="0"
                        style="background-color: rgb(69, 78, 112)"
                        v-if="!loading"
                        @click="
                          $event.stopPropagation();
                          removeConnectedBleDevice(item.deviceName);
                        "
                      >
                        <v-icon>mdi-close </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </template>
                </content-item>
              </div>
              <div v-else style="color: #e0e0e0">No data</div>
            </v-tab-item>
            <v-tab-item value="MH">
              <div v-if="listMHDevices.length > 0">
                <content-item
                  v-for="(item, index) in listMHDevices"
                  :key="index"
                  :item="item"
                  @view="onDeviceClicked(item.deviceName)"
                >
                  <template v-slot:controller>
                    <v-list-item-icon class="pt-0 pr-1">
                      <v-progress-circular
                        :size="26"
                        :width="3"
                        indeterminate
                        color="white"
                        v-if="loading"
                      ></v-progress-circular>

                      <v-btn
                        small
                        color="#eeeeee"
                        class="service_button"
                        fab
                        elevation="0"
                        style="background-color: rgb(69, 78, 112)"
                        v-if="!loading"
                        @click="
                          $event.stopPropagation();
                          removeConnectedBleDevice(item.deviceName);
                        "
                      >
                        <v-icon>mdi-close </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </template>
                </content-item>
              </div>
              <div v-else style="color: #e0e0e0">No data</div>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <!-- <div class="content_card mt-5" v-if="connectedDeviceOptions.length">
          <h4 class="mt-1 mb-3" style="color: #e0e0e0">
            {{ `${$t("common.connectedDevice")}` }}:
          </h4>
          <content-item
            v-for="(item, index) in connectedDeviceOptions"
            :key="index"
            :item="item"
            @view="onDeviceClicked(item.deviceName)"
          >
            <template v-slot:controller>
              <v-list-item-icon class="pt-0 pr-1">
                <v-progress-circular
                  :size="26"
                  :width="3"
                  indeterminate
                  color="white"
                  v-if="loading"
                ></v-progress-circular>

                <v-btn
                  small
                  color="#eeeeee"
                  class="service_button"
                  fab
                  elevation="0"
                  style="background-color: rgb(69, 78, 112)"
                  v-if="!loading"
                  @click="
                    $event.stopPropagation();
                    removeConnectedBleDevice(item.deviceName);
                  "
                >
                  <v-icon>mdi-close </v-icon>
                </v-btn>
              </v-list-item-icon>
            </template>
          </content-item>
        </div> -->
      </v-col>
    </v-row>
    <div v-show="isMeasuring" class="ble_measuring_container">
      <div class="ble_result_container">
        <div
          ref="lav2Container"
          style="width: 70px; height: 70px; margin: 10px auto -8px auto"
          :style="{ opacity: deviceConnected ? 1 : 0 }"
        ></div>
        <h3>
          {{ device.name }}
        </h3>
        <h4 v-if="batteryLevel">
          <v-icon small style="margin-right: -3px"
            >mdi-battery{{
              batteryPercent
                ? batteryPercent == 100
                  ? ""
                  : "-" + batteryPercent
                : "-outline"
            }}</v-icon
          >
          {{ batteryLevel ? `${batteryLevel}%` : "" }}
        </h4>
        <h5
          class="no_data"
          style="font-size: 80%"
          :style="{ opacity: episode.datapoints.length ? 1 : 0 }"
        >
          ♡ {{ episode.datapoints.length }}
        </h5>

        <table>
          <tr>
            <th rowspan="2" style="border-right: 1px solid #e0e0e0; width: 60%">
              <h3 class="name">
                <v-icon>mdi-heart</v-icon>
                {{ $t("common.heartRate") }}:
              </h3>
              <h1 class="value">
                {{ data.pulse || "---" }}<span class="sub_text">bpm</span>
              </h1>
            </th>
            <td class="pr-2 pl-1">
              <h3 class="name right">{{ $t("common.sys") }}:</h3>
            </td>
            <td>
              <h1 class="value sub">{{ data.sys || "---" }}</h1>
            </td>
          </tr>
          <tr>
            <td class="pr-2 pl-1">
              <h3 class="name right">{{ $t("common.dias") }}:</h3>
            </td>
            <td>
              <h1 class="value sub">{{ data.dia || "---" }}</h1>
            </td>
          </tr>
        </table>

        <div v-if="timeOfNextMeasurement" class="info_next_measurement">
          {{ $t("common.next_measurement_time") }} :
          <p class="time">{{ timeOfNextMeasurement }}</p>
        </div>

        <span
          class="center-text mt-5 mb-5 pink--text"
          v-if="errorCodes && errorCodes.length"
        >
          <h3 class="name center-text mb-2 mt-5">
            {{ this.$t("error.errorOccured") }}:
          </h3>
          <b>● {{ errorCodes.join(" ● ") }}</b>
        </span>

        <h3 class="name center-text mb-5 mt-5">
          {{ $t("common.cuffPressure") }}:
          <span style="margin-right: 5px"> {{ currentPressure || "---" }} </span
          >mmHg
        </h3>
        <h3 class="name center-text mb-1 mt-0">
          {{ $t("common.maxInflatePressure") }}:
        </h3>
        <div class="select-custom" style="width: 220px; margin: 0 auto 0 auto">
          <v-select
            v-model="episode.config.maxInflatePressure"
            filled
            outlined
            required
            hide-details
            :items="pressureOptions"
          ></v-select>
        </div>

        <div class="mt-5" v-show="episode.datapoints.length">
          <h3>
            <v-icon>mdi-chart-line</v-icon>
            {{ `${$t("common.chart")}` }}:
          </h3>
          <div class="table">
            <highcharts :options="chartOptions"></highcharts>
          </div>
        </div>
        <div class="mt-5">
          <h3>
            <v-icon>mdi-clipboard-outline</v-icon>
            {{ $t("common.patient") }}:
          </h3>
          <patient
            ref="PatientProfileSelector"
            :allowPatientList="true"
            v-model="episode.patientId"
            @selectedProfile="onPatientSelected"
            @selectedProfileOffline="onSelectedProfileOffline"
          />
        </div>
        <div class="mt-5">
          <h3>
            <v-icon>mdi-table</v-icon>
            {{ $t("common.datapoints") }}:
          </h3>
          <v-data-table
            :headers="headers"
            :items="datapointsTable"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.pulse="{ item }">
              <v-icon style="font-size: 1rem">mdi-heart-outline</v-icon>
              {{ item.pulse }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.status="{ item }">
              <v-badge
                style="font-size: 10px"
                :color="
                  item.status == 1
                    ? 'red'
                    : item.status == 2
                    ? 'orange'
                    : item.status == 3
                    ? 'green'
                    : 'cyan'
                "
              />
            </template>
          </v-data-table>

          <v-data-table
            v-if="datapointsTableError.length"
            :headers="headersError"
            :items="datapointsTableError"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
          </v-data-table>
        </div>
        <div class="mt-5">
          <h3>{{ $t("common.clinicalNote") }}</h3>
          <text-editor v-model="episode.note" />
          <!-- <textarea v-model="episode.note"></textarea> -->
        </div>
        <div class="d-flex mt-5 mb-5">
          <v-spacer />
          <v-btn
            @click="saveAndFinishEpisode"
            class="mt-2 custom pa-6 pl-8 pr-8"
            :loading="saveEpisodeLoading"
            style="min-width: 250px"
          >
            <v-icon class="mr-2">mdi-floppy</v-icon>
            {{ $t("common.finishEpisode") }}
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!isNative"
            @click="downloadExcel"
            class="mt-2 custom pa-6 pl-8 pr-8"
          >
            <v-icon class="mr-2">mdi-download</v-icon>
            download data
          </v-btn>
          <v-spacer v-if="!isNative" />
        </div>
      </div>
    </div>
    <ble-config
      ref="BleConfig"
      v-model="episode.config"
      :deviceName="device && device.name"
      :batteryLevel="batteryLevel"
    />
    <v-btn
      fixed
      style="left: calc(50vw - 30px); bottom: 85px"
      fab
      large
      color="#66BB6A"
      class="white--text"
      v-if="showBtnRestartCalibManually"
      @click="restartCalibManually"
    >
      <v-icon x-large>mdi-play</v-icon>
    </v-btn>
    <v-btn
      fixed
      style="left: calc(50vw - 30px); bottom: 85px"
      fab
      large
      color="#EF5350"
      class="white--text"
      v-if="showBtnStopCurrentCalib"
      @click="stopCurrentCalib"
    >
      <v-icon x-large>mdi-stop</v-icon>
    </v-btn>
    <popup-confirm
      ref="PopupConfirm"
      :title="$t('common.reconnect')"
      :message="$t('common.reconnectMessage')"
    />
  </div>
</template>

<script>
import lottie from "lottie-web";
import TextEditor from "./ckeditor/TextEditor.vue";
import moment from "moment";
import BleConfig from "@/components/BleConfig.vue";
import {
  HeartRateBleDevice,
  initializeBle,
  scanBleDevice,
  stopBleScan,
  forceDisconnectDeviceId,
} from "@/plugins/hasaky.js";
import Patient from "./patient/Patient.vue";
import ContentItem from "@/components/cards/ContentItem";
import { mapGetters } from "vuex";
import { pulseChartOptions } from "./ChartOptions";
import axios from "axios";
import { EventName } from "@/plugins/constants";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  sleep,
} from "@/plugins/helpers";
import PopupConfirm from "./PopupConfirm.vue";

const testConfigs = [
  { maxInflatePressure: 160, calibsPerHour: 0, counts: 5 },
  { maxInflatePressure: 180, calibsPerHour: 0, counts: 5 },
  { maxInflatePressure: 220, calibsPerHour: 0, counts: 5 },
  { maxInflatePressure: 250, calibsPerHour: 0, counts: 5 },
];

function defaultEpisode(uid) {
  return {
    id: `${moment().format()}_${uid}`,
    uid: uid,
    name: "",
    date: moment().format(),
    config: {
      maxInflatePressure: 160,
      calibsPerHour: 0,
      deviceName: "",
      deviceId: "",
    },
    datapoints: [],
    note: "",
    patientId: null,
  };
}

import { registerPlugin } from "@capacitor/core";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

export default {
  components: {
    TextEditor,
    BleConfig,
    Patient,
    ContentItem,
    PopupConfirm,
  },
  computed: {
    pressureOptions() {
      return [
        { text: "160 mmHg", value: 160 },
        { text: "180 mmHg", value: 180 },
        { text: "220 mmHg", value: 220 },
        { text: "250 mmHg", value: 250 },
      ];
    },
    width() {
      return 220;
    },
    height() {
      return 220;
    },
    style() {
      return {
        width: this.width ? `${this.width}px` : "100%",
        height: this.height ? `${this.height}px` : "100%",
        overflow: "hidden",
        margin: "0",
        opacity: this.bleAvailable ? 0.8 : 0.5,
      };
    },
    ...mapGetters("Scanner", [
      "getCurrentEpisode",
      "getBleDevices",
      "getScannedDeviceId",
      "getScannedTbqId",
    ]),
    ...mapGetters("Mobile", ["getSubscriptions"]),
    connectedDeviceOptions() {
      var devices = this.$store.getters["Scanner/getBleDevices"];
      if (!devices) return [];

      var uniqueDeviceNames = [...new Set(devices.map((d) => d.deviceName))];

      return uniqueDeviceNames.map((deviceName) => ({
        deviceName,
        deviceId: devices.find((d) => d.deviceName == deviceName).deviceId,
        title: deviceName,
        content: "",
        // message: `<span style="color:white;font-size:75% !important;">${d.deviceId}</span>`,
        message: "",
        image: "/device.png",
      }));
    },
    listTBQDevices() {
      const listDevices = this.connectedDeviceOptions.filter((i) =>
        i.deviceName.includes("TBQ")
      );

      listDevices.forEach((element) => {
        this.listTBQStatus[element.deviceId] = {
          isOnline: false,
          isError: false,
        };
      });

      listDevices.forEach((device) => {
        this.registerDataWatcher(device.deviceId);
        this.checkOnlineStatus(device.deviceId);
      });

      return listDevices;
    },
    listOtherDevices() {
      return this.connectedDeviceOptions.filter(
        (i) => !i.deviceName.includes("TBQ")
      );
    },
    listMHDevices() {
      return [];
    },
  },
  watch: {
    bleAvailable(val) {
      this.renderBleAnimation(val);
    },
    getScannedDeviceId(val) {
      if (!val) return;
      this.connectBleDevice(val);
      this.$store.commit("Scanner/SET_SCANNED_DEVICE_ID", null);
    },
    getScannedTbqId(val) {
      if (!val) return;
      this.connectTbqDevice(val);
      this.$store.commit("Scanner/SET_SCANNED_TBQ_ID", null);
    },
  },
  data() {
    return {
      isTesting: false,
      testCounterIndex: 1,
      testConfigIndex: 1,
      headers: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: "mmHg", value: "maxInflatePressure", sortable: false },
        { text: this.$t("common.sys"), value: "sys", sortable: false },
        { text: this.$t("common.dias"), value: "dia", sortable: false },
        { text: this.$t("common.heartRate"), value: "pulse", sortable: false },
        { text: "", value: "status", sortable: false },
      ],
      headersError: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: "mmHg", value: "maxInflatePressure", sortable: false },
        { text: this.$t("error.errorCode"), value: "message", sortable: false },
      ],
      batteryPercent: null,
      errorCodes: [],
      datapointsTable: [],
      datapointsTableError: [],
      saveEpisodeLoading: false,
      batteryLevel: 0,
      chartOptions: {},
      patientSelected: {},
      bleDevice: null,
      episode: defaultEpisode(this.$uid),
      isMeasuring: false,
      deviceConnected: false,
      showBtnRestartCalibManually: false,
      showBtnStopCurrentCalib: false,
      timeoutErrorNoScannedResult: null,
      bleAvailable: null,
      loading: false,
      device: {},
      data: {},
      currentPressure: "",
      timeOfNextMeasurement: "",
      categoriesTabs: [
        { name: "HOLTER", title: "Holter" },
        { name: "TBQ", title: "Tủ bảo quản" },
      ],
      handlers: {
        onDisConnect: (device) => {
          var { name } = device;
          console.log(`Device ${name} is disconnected.`);
          this.deviceConnected = false;
          this.currentPressure = "";
          this.showBtnStopCurrentCalib = false;
          this.showBtnRestartCalibManually = false;
          if (this.episode.config.calibsPerHour) {
            // var secondCount =
            //   Math.floor((60 * 60) / this.episode.config.calibsPerHour) - 33;
            //this.$refs.CountDown.startCountDown(secondCount);

            //Caculate time of next measurement
            var secondCount = Math.floor(
              (60 * 60) / this.episode.config.calibsPerHour
            );
            this.displayTimeOfNextMeasurement(secondCount);
          }
        },
        onReconnect: (deviceId) => {
          if (this.episode.config.calibsPerHour) {
            //this.$refs.CountDown.startCountDown(30);
          }
          console.log("Reconnect device success", deviceId);
          this.deviceConnected = true;
          this.errorCodes = [];
          if (this.episode.config.calibsPerHour) {
            this.showBtnStopCurrentCalib = false;
            this.showBtnRestartCalibManually = false;
          } else {
            this.showBtnStopCurrentCalib = false;
            this.showBtnRestartCalibManually = true;
          }
        },
        onBloodPressureValue: async ({ sys, dia, pulse, errorCodeBits16 }) => {
          var config = this.isTesting
            ? testConfigs[this.testConfigIndex - 1]
            : this.episode.config;
          var { maxInflatePressure } = config;
          if (errorCodeBits16) {
            this.errorCodes = this.getErrorCodesFrom16Bits(errorCodeBits16);
            if (this.episode.config.calibsPerHour) {
              this.errorCodes.push(this.$t("common.waitNextTurn"));
            }
            this.playDing();
            var errorMessage = this.errorCodes.join(", ");
            this.datapointsTableError.push({
              ind: this.datapointsTableError.length + 1,
              date: moment().format("DD-MM-YYYY HH:mm"),
              message: errorMessage,
              maxInflatePressure,
            });
            this.$dbSet(
              `episodesError/${this.episode.id}`,
              this.datapointsTableError
            );
            this.showErrorPopup(errorMessage);
            if (this.episode.config.calibsPerHour == 0) {
              this.showBtnStopCurrentCalib = false;
              this.showBtnRestartCalibManually = true;
            } else {
              this.showBtnStopCurrentCalib = false;
              this.showBtnRestartCalibManually = false;
            }
          } else {
            if (!sys || !dia || !pulse) return;
            if (sys > 350 || dia > 350 || pulse > 350) return;
            this.notifyDatapoint({ sys, dia, pulse });
            this.data = {
              sys,
              dia,
              pulse,
              date: moment().format(),
              maxInflatePressure,
            };
            var hasSound = this.$accountLevel > 0 ? true : false;
            this.calibBleSeverity(this.data, hasSound);
            this.episode.datapoints.push(this.data);
            this.episode.datapoints = this.sortDatapoints(
              this.episode.datapoints
            );
            // this.saveEpisode();
            this.saveDataPoints(this.episode.datapoints);
            this.renderChart(this.episode.datapoints);
            this.renderDataTable(this.episode.datapoints);
            if (this.episode.config.calibsPerHour == 0) {
              this.showBtnStopCurrentCalib = false;
              this.showBtnRestartCalibManually = true;
            } else {
              // Nếu là đo theo chu kì,
              // Khi ra data ẩn hết các nút
              this.showBtnStopCurrentCalib = false;
              this.showBtnRestartCalibManually = false;
            }
          }
          if (this.isTesting) {
            this.runTesting();
          }
        },
        onCuffPressurevalue: (value) => {
          this.currentPressure = value;
          this.timeOfNextMeasurement = "";
        },
        onBatteryLevel: (value) => {
          if (value) {
            if (this.batteryLevel) {
              var diff = Math.abs(value - this.batteryLevel);
              if (diff > 20) return;
            }
            this.batteryLevel = value;
            this.batteryPercent = this.roundBatteryPercentage(value);
          }
        },
      },
      tabs: null,
      listTBQStatus: {},
      onlineInterval: null,
      isTrackingOnline: false,
    };
  },
  async mounted() {
    this.clean();
    this.bleAvailable = await initializeBle();
    this.$store.commit("Scanner/SET_IS_MEASURING", false);
    await this.resumeLastMeasurement();
  },
  async created() {},
  methods: {
    async registerBackgroundMode() {
      BackgroundGeolocation.addWatcher(
        {
          // If the "backgroundMessage" option is defined, the watcher will
          // provide location updates whether the app is in the background or the
          // foreground. If it is not defined, location updates are only
          // guaranteed in the foreground. This is true on both platforms.

          // On Android, a notification must be shown to continue receiving
          // location updates in the background. This option specifies the text of
          // that notification.
          backgroundMessage: "Cancel to prevent battery drain.",

          // The title of the notification mentioned above. Defaults to "Using
          // your location".
          backgroundTitle: "Start measuring in background.",

          // Whether permissions should be requested from the user automatically,
          // if they are not already granted. Defaults to "true".
          requestPermissions: true,

          // If "true", stale locations may be delivered while the device
          // obtains a GPS fix. You are responsible for checking the "time"
          // property. If "false", locations are guaranteed to be up to date.
          // Defaults to "false".
          stale: false,

          // The minimum number of metres between subsequent locations. Defaults
          // to 0.
          distanceFilter: 50,
        },
        function callback(location, error) {
          if (error) {
            if (error.code === "NOT_AUTHORIZED") {
              if (
                window.confirm(
                  "This app needs your permission to run in background, " +
                    "but does not have permission.\n\n" +
                    "Open settings now?"
                )
              ) {
                // It can be useful to direct the user to their device's
                // settings when location permissions have been denied. The
                // plugin provides the 'openSettings' method to do exactly
                // this.
                BackgroundGeolocation.openSettings();
              }
            }
            console.log(">>>>>>Loc Error", error);
            return console.error(error);
          }
          console.log(">>>>>>Location: ", location);
          return console.log(location);
        }
      ).then(function after_the_watcher_has_been_added(watcher_id) {
        // // When a watcher is no longer needed, it should be removed by calling
        // // 'removeWatcher' with an object containing its ID.
        // BackgroundGeolocation.removeWatcher({
        //   id: watcher_id,
        // });
        console.log(">>>>>> watcher_id", watcher_id);
      });
    },
    async startCheckingOnlineStatus() {
      const uid = this.$uid;
      this.isTrackingOnline = true;
      var payload = {
        uid: uid,
      };
      await axios.post(
        `https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/checkOnlineMeasuring?uid=${uid}`,
        payload,
        { headers: { uid } }
      );
    },
    async sendCurrentTimeTrackToFirebase() {
      console.log("sendCurrentTimeTrackToFirebase", moment().format());
      await this.$dbSet(
        `users/${this.$uid}/online-tracking`,
        moment().format()
      );
      this.onlineInterval = setTimeout(async () => {
        if (this.onlineInterval) clearTimeout(this.onlineInterval);
        this.onlineInterval = null;
        if (this.isTrackingOnline) await this.sendCurrentTimeTrackToFirebase();
      }, 10000);
    },
    async stopCheckingOnlineStatus() {
      this.isTrackingOnline = false;
      if (this.onlineInterval) clearTimeout(this.onlineInterval);
      await this.$dbSet(`users/${this.$uid}/online-tracking`, "");
    },
    async checkOnlineStatus(deviceId, item) {
      if (!item) return;

      var isError = item.hien_thi_loi && item.hien_thi_loi != "0";

      const device = this.connectedDeviceOptions.find(
        (i) => i.deviceId == deviceId
      );
      if (!device) return;
      this.listTBQStatus[deviceId].isError = isError;

      if (!item || !item.tickLabel) {
        this.listTBQStatus[deviceId].isOnline = false;
        return;
      }

      if (moment(item.tickLabel).add(5, "seconds").isAfter(moment())) {
        this.listTBQStatus[deviceId].isOnline = true;
      } else {
        this.listTBQStatus[deviceId].isOnline = false;
      }
      this.$forceUpdate();
    },
    async registerDataWatcher(deviceId) {
      this.$dbWatcherNoPrefix(`holtervn/${deviceId}/realtime`, async (data) => {
        if (!data || !data.tickLabel) return;
        await this.checkOnlineStatus(deviceId, data);
      });
    },
    downloadExcel() {
      this.downloadDatapointsAsExcel(this.episode, this.datapointsTableError);
    },
    async runTesting() {
      var config = testConfigs[this.testConfigIndex - 1];

      var maxCounter = config.counts;
      if (this.testCounterIndex < maxCounter) {
        this.testCounterIndex++;
        await this.bleDevice.applyConfig(config);
        return;
      }
      this.testCounterIndex = 0;
      if (this.testConfigIndex < testConfigs.length) {
        this.testConfigIndex++;
        this.runTesting();
        return;
      }
      this.isTesting = false;
      this.testConfigIndex = 1;
      this.testCounterIndex = 1;
      // this.playSfxRepeated();
      this.downloadDatapointsAsExcel(this.episode, this.datapointsTableError);
    },
    async renderEpisode(id = "") {
      id = id.split(" ").join("+");
      if (!id) return;
      this.loading = true;
      var episode = await this.$dbGet(`episodes/${id}`);
      if (!episode) {
        this.loading = false;
        return;
      }
      this.episode = episode;
      var { datapoints } = episode;
      if (datapoints) {
        datapoints = this.sortDatapoints(datapoints);
        this.renderDataTable(datapoints);
        this.renderChart(datapoints);
      }
      this.loading = false;
    },
    async renderDataTable(points = []) {
      this.datapointsTable = points.reverse().map((p, ind) => ({
        ...p,
        ind: ind + 1,
        date: moment(p.date).format("DD-MM-YYYY HH:mm"),
        status: this.calibDataSeverity(p),
      }));
    },
    async removeConnectedBleDevice(deviceName) {
      if (!confirm(this.$t("common.deleteDevice"))) return;
      this.$store.commit("Scanner/REMOVE_BLE_DEVICE", deviceName);
    },
    async openPromptDeviceId() {
      let deviceId = prompt(this.$t("common.inputDeviceID"));
      if (!deviceId) return;
      this.connectBleDevice(deviceId);
    },
    async registerConnectBleDeviceTimeout(device) {
      if (!device) return;
      this.timeoutErrorNoScannedResult = setTimeout(async () => {
        this.clearConnectBleDeviceTimeout();

        this.saveEpisodeLoading = false;
        this.loading = false;
        console.log("stopped scanning");
        await stopBleScan();
        if (!device.isConnected) {
          alert(this.$t("common.cannotFindAnyDevice"));
        }
      }, 18000);
    },
    async notifyDatapoint({ sys, dia, pulse }) {
      if (!this.networkStatus) return;
      var payload = {
        uid: this.$uid,
        datapoint: {
          sys,
          dia,
          pulse,
        },
      };
      var resp = await axios.post(
        "https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/notifyNewDataPoint",
        payload
      );
    },
    async clearConnectBleDeviceTimeout() {
      clearTimeout(this.timeoutErrorNoScannedResult);
      this.timeoutErrorNoScannedResult = null;
    },
    // Web version to connect with BLE
    async requestDevices() {
      // try to disconnect any existing device first
      if (this.bleDevice && this.bleDevice.isConnected) {
        await this.bleDevice.disconnect();
      }
      var device = new HeartRateBleDevice(this.handlers);
      var result = await device.requestDevice();
      if (!result) {
        this.loading = false;
        return;
      }
      this.handleBleDevice(device);
    },
    // Mobile version to connect with BLE
    async connectTbqDevice(deviceName) {
      if (!confirm(this.$t("common.confirmConnectDevice") + deviceName + "?")) {
        return;
      }
      this.$store.commit("Scanner/ADD_BLE_DEVICE", {
        deviceId: deviceName,
        deviceName,
      });
      this.$router.push(`/tbq/${deviceName}`);
    },
    async onDeviceClicked(deviceName) {
      if (this.checkTbqDeviceName(deviceName)) {
        this.$router.push(`/tbq/${deviceName}`);
      } else {
        this.connectBleDevice(deviceName);
      }
    },
    async connectBleDevice(deviceName) {
      if (!confirm(this.$t("common.confirmConnectDevice") + deviceName + "?")) {
        return;
      }
      this.episode.config.deviceName = deviceName;
      try {
        // try to disconnect any existing device first
        if (this.bleDevice && this.bleDevice.isConnected) {
          await this.bleDevice.disconnect();
        }
        this.loading = true;
        var option = this.connectedDeviceOptions.find(
          (op) => op.deviceName == deviceName
        );
        if (option && option.deviceId) {
          await forceDisconnectDeviceId(option.deviceId);
        }
        var device = new HeartRateBleDevice(this.handlers);
        this.registerConnectBleDeviceTimeout(device);

        var scanResult = await scanBleDevice(deviceName);
        if (!(await device.connectDevice(scanResult))) {
          this.clearConnectBleDeviceTimeout();
          this.loading = false;
          return;
        }
        this.handleBleDevice(device);
      } catch (error) {
        this.loading = false;
        console.log("Argh! " + error);
      }
    },
    async handleBleDevice(device) {
      try {
        this.bleDevice = device;
        this.$store.commit("Scanner/ADD_BLE_DEVICE", {
          deviceId: device.getDeviceId(),
          deviceName: device.getDeviceName(),
        });
        this.device = { name: device.getDeviceName() };
        this.episode.config.deviceId = device.getDeviceId();
        this.episode.config.deviceName = device.getDeviceName();
        if (!this.checkHolterDeviceLevel(device.getDeviceName())) {
          this.clearConnectBleDeviceTimeout();
          this.bleDevice.disconnect();
          this.loading = false;
          alert(this.$t("error.deviceCodeNotRecognized"));
          return;
        }
        this.setAccountLevelByDeviceName(device.getDeviceName());
        this.episode.patientId = this.$phone;
        var runType = await this.$refs.BleConfig.confirm();
        if (!runType) {
          this.clearConnectBleDeviceTimeout();
          await this.bleDevice.disconnect();
          this.loading = false;
          return;
        }
        if (runType == "runTest") {
          this.isTesting = true;
          this.testCounterIndex = 1;
          this.testConfigIndex = 1;
        }
        var config =
          runType == "runTest" ? testConfigs[0] : this.episode.config;
        if (!config) {
          alert("Error: cannot init new episode!");
          return;
        }
        var { maxInflatePressure } = config;
        if (!maxInflatePressure) {
          alert("Error: cannot init new episode!");
          return;
        }

        await this.bleDevice.applyConfig(config);
        await this.bleDevice.startListening();

        if (config.calibsPerHour == 0) {
          this.showBtnRestartCalibManually = false;
          this.showBtnStopCurrentCalib = true;
        } else {
          this.showBtnRestartCalibManually = false;
          this.showBtnStopCurrentCalib = false;
        }

        this.isMeasuring = true;
        this.deviceConnected = true;
        this.$store.commit("Scanner/SET_IS_MEASURING", true);

        if (this.$route.query.eid) {
          await this.renderEpisode(this.$route.query.eid);
        } else {
          await this.saveEpisode();
          await this.autoRegisterUserAsPatient();
        }
      } catch (error) {
        console.log("Argh! " + error);
      }
    },
    async autoRegisterUserAsPatient() {
      if (!this.networkStatus) return;
      try {
        var phoneString = this.$store.getters["Authen/getUser"].phone || "";
        if (!phoneString) return;
        phoneString = this.formatPhoneNumber(phoneString);
        this.episode.patientId = phoneString;
        var patientObj = await this.$dbGet(`patients/${phoneString}`);
        if (!patientObj) return;
        this.$refs.PatientProfileSelector.renderProfile(phoneString);
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/patientId`,
          phoneString
        );
        for (var subscriber of this.getSubscriptions) {
          this.$dbSet(
            `users/${subscriber.id}/episodes/${this.episode.id}/patientId`,
            phoneString
          );
        }
        this.$dbSet(`episodes/${this.episode.id}/patientId`, phoneString);
        this.onPatientSelected(patientObj);
      } catch (err) {
        console.log("autoRegisterUserAsPatient Error! " + err.message);
      }
    },
    async restartCalibManually() {
      try {
        this.errorCodes = [];
        await this.bleDevice.applyConfig(this.episode.config);
        if (this.episode.config.calibsPerHour == 0) {
          this.showBtnStopCurrentCalib = true;
          this.showBtnRestartCalibManually = false;
        } else {
          this.showBtnStopCurrentCalib = false;
          this.showBtnRestartCalibManually = false;
        }
      } catch (err) {
        console.log("restartCalibManually Error! " + err.message);
      }
    },
    async stopCurrentCalib() {
      await this.bleDevice.stopCalib();
      this.showBtnStopCurrentCalib = false;
      this.showBtnRestartCalibManually = true;
    },
    async saveDataPoints(datapoints) {
      try {
        var id = this.episode.id;
        if (!id) return;
        this.$dbSet(`episodes/${id}/datapoints`, datapoints);
        this.$dbSet(
          `users/${this.$uid}/episodes/${id}/datacounts`,
          datapoints.length
        );
        for (var subscriber of this.getSubscriptions) {
          this.$dbSet(
            `users/${subscriber.id}/episodes/${id}/datacounts`,
            datapoints.length
          );
        }
      } catch (err) {
        console.log("saveDataPoints Error! " + err.message);
      }
    },
    async saveClinicalNote() {
      try {
        var id = this.episode.id;
        if (!id) return;
        await this.$dbSet(`episodes/${id}/note`, this.episode.note);
      } catch (err) {
        console.log("saveClinicalNote Error! " + err.message);
      }
    },
    async saveEpisode() {
      try {
        this.saveEpisodeLoading = true;
        this.episode.name = `${moment().format("YYYYMMDD-hhmmss")}`;
        if (this.patientSelected && this.patientSelected.fullName) {
          this.episode.name += `-${this.patientSelected.fullName}`;
        }
        var data = JSON.parse(JSON.stringify(this.episode));
        await this.$dbSet(`episodes/${data.id}`, data);
        console.log("data", data);
        var lookup = JSON.parse(JSON.stringify(data));
        delete lookup.note;
        delete lookup.datapoints;
        await this.$dbSet(`users/${this.$uid}/episodes/${data.id}`, lookup);
        for (var subscriber of this.getSubscriptions) {
          this.$dbSet(`users/${subscriber.id}/episodes/${data.id}`, lookup);
        }
        this.saveEpisodeLoading = false;
        //this.$refs.CountDown.stopCountDown();

        //Set currentEpisodeId to localstorage, using just in casing user refesh or disconnect app
        setLocalStorage("EPISODE_MEASURING", data.id.toString());

        //Visible time of next measurement
        this.timeOfNextMeasurement = "";
        this.startCheckingOnlineStatus();
        this.sendCurrentTimeTrackToFirebase();
        this.registerBackgroundMode();
      } catch (err) {
        this.saveEpisodeLoading = false;
        console.log("Save episode error:", err);
      }
    },
    async saveAndFinishEpisode() {
      if (!confirm(this.$t("common.finishEpisodeConfirm"))) return;
      // var episodeId = this.episode.id;
      this.stopCheckingOnlineStatus();
      try {
        this.saveEpisodeLoading = true;
        this.deviceConnected = false;
        // disconnect device
        if (this.bleDevice && this.bleDevice.isConnected) {
          await this.bleDevice.disconnect();
        }
        await this.sleep(200);

        try {
          this.sendDataToSandrasoftIframe(
            EventName.onEpisodeDatapoints,
            this.episode
          );
        } catch {
          console.log("sendDataToSandrasoftIframe Errored");
        }

        // When finish episode, always remove this patientId from the current wacher
        if (this.patientSelected && this.patientSelected.patientId) {
          this.$dbRemove(
            `patients/${this.patientSelected.patientId}/currentEpisodeId`
          );
        }
        if (!this.networkStatus) {
          this.$store.commit("Offline/ADD_OFFLINE_EPISODE", this.episode);
        }
        this.saveClinicalNote();
        this.saveEpisodeLoading = false;
        this.isMeasuring = false;
        this.showSuccess(this.$t("common.successMessage"));
        this.clean();
        this.$store.commit("Scanner/SET_IS_MEASURING", false);

        removeLocalStorage("EPISODE_MEASURING");

        await this.sleep(2000);
        this.clean();
      } catch (err) {
        this.saveEpisodeLoading = false;
        console.log("saveAndFinishEpisode", err);
        this.showErrorPopup(this.$t("common.errorMessage"));
        this.$store.commit("Scanner/SET_IS_MEASURING", false);
      }
    },
    async onSelectedProfileOffline(patientObject = {}) {
      this.patientSelected = patientObject;
      var { fullName } = patientObject;
      this.episode.name += `-${fullName}`;
      this.episode.patientObject = patientObject;
    },
    async onPatientSelected(patient = {}) {
      try {
        // Firstly, check if old patient has data ...
        if (this.patientSelected && this.patientSelected.patientId) {
          await this.$dbRemove(
            `patients/${this.patientSelected.patientId}/currentEpisodeId`
          );
        }
        this.patientSelected = patient;
        var { patientId, fullName } = patient;
        var episodeName = (this.episode.name += `-${fullName}`);
        this.$dbSet(`patients/${patientId}/currentEpisodeId`, this.episode.id);
        this.$dbSet(`episodes/${this.episode.id}/name`, episodeName);
        this.$dbSet(`episodes/${this.episode.id}/patientId`, patientId);
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/name`,
          episodeName
        );
        for (var subscriber1 of this.getSubscriptions) {
          this.$dbSet(
            `users/${subscriber1.id}/episodes/${this.episode.id}/name`,
            episodeName
          );
        }
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/patientId`,
          patientId
        );
        for (var subscriber2 of this.getSubscriptions) {
          this.$dbSet(
            `users/${subscriber2.id}/episodes/${this.episode.id}/patientId`,
            patientId
          );
        }
      } catch (err) {
        console.log("onPatientSelected Error! " + err.message);
      }
    },
    async renderChart(points = []) {
      var pulses = [];
      var ranges = [];
      for (var point of points) {
        var timestamp = moment(point.date).valueOf();
        if (point.pulse) {
          pulses.push([timestamp, point.pulse]);
        }
        if (point.sys && point.dia) {
          ranges.push([timestamp, point.sys, point.dia]);
        }
      }

      this.chartOptions = {
        ...pulseChartOptions,
        series: [
          {
            name: this.$t("common.heartRate"),
            data: pulses,
            type: "spline",
            zIndex: 1,
            color: "#F06292",
            marker: {
              radius: 4,
              fillColor: "#e0e0e0",
              lineWidth: 2,
              lineColor: "#EC407A",
            },
          },
          {
            name: this.$t("common.bloodPressure"),
            data: ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "#E1F5FE",
            fillOpacity: 0.7,
            zIndex: 0,
            marker: {
              radius: 3,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#039BE5",
              symbol: "circle",
              // enabled: false,
            },
          },
        ],
      };
    },
    renderBleAnimation(rippleEffect = false) {
      lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: "svg",
        loop: rippleEffect,
        autoplay: rippleEffect,
        path: "/lottie/ble2.json",
      });
      lottie.loadAnimation({
        container: this.$refs.lav2Container,
        renderer: "svg",
        loop: rippleEffect,
        autoplay: rippleEffect,
        path: "/lottie/ble3.json",
      });
    },
    async clean() {
      this.clearConnectBleDeviceTimeout();

      this.datapointsTable = [];
      this.datapointsTableError = [];
      this.chartOptions = {};
      this.patientSelected = {};
      this.isMeasuring = false;
      this.loading = false;
      this.saveEpisodeLoading = false;
      this.device = {
        name: "HolterVN",
      };
      this.deviceConnected = false;
      this.data = {
        pulse: null,
        sys: null,
        dia: null,
      };
      this.episode = defaultEpisode(this.$uid);
      this.showBtnStopCurrentCalib = false;
      this.showBtnRestartCalibManually = false;
      this.errorCodes = [];
      this.batteryLevel = null;
      this.batteryPercent = null;
    },
    async mapAllEpisodes() {
      var episodes = await this.$dbGet("episodes");
      for (var episodeId of Object.keys(episodes)) {
        var episode = episodes[episodeId];
        console.log(episode);
        episode.datacounts = episode.datapoints.length;
        await this.$dbSet(`users/${this.$uid}/episodes/${episodeId}`, episode);
      }
    },
    async testSaveDatapoints() {
      var id = `2022-04-12T19:08:02+07:00_l24EDgyuxcMnt5vHuG4WLxkNDZl2`;
      this.$dbSet(`episodes/${id}/datapoints`, [
        {
          date: "2022-04-07T14:52:49+07:00",
          dia: 72,
          pulse: 73,
          sys: 106,
        },
      ]);
    },
    async saveTestClinicalNote() {
      var id = `2022-04-12T19:08:02+07:00_l24EDgyuxcMnt5vHuG4WLxkNDZl2`;
      this.$dbSet(`episodes/${id}/note`, "Okay");
    },
    displayTimeOfNextMeasurement(secondCount) {
      // Get the current date and time
      var currentDate = new Date();
      currentDate.setSeconds(currentDate.getSeconds() + secondCount);

      this.timeOfNextMeasurement = moment(currentDate).format("h:mm:ss A");
    },
    async resumeLastMeasurement() {
      // 1. Get episode data from server
      const episodeId = getLocalStorage("EPISODE_MEASURING");
      if (!episodeId) return;

      var episode = await this.$dbGet(`episodes/${episodeId}`);
      if (!episode) return;

      // 2. Show Episode View
      this.isTrackingOnline = true;
      this.sendCurrentTimeTrackToFirebase();
      this.isMeasuring = true;
      this.$store.commit("Scanner/SET_IS_MEASURING", true);

      await this.sleep(1000);
      this.episode = episode;

      // 3. Render Charts
      var { datapoints, config } = episode;
      if (datapoints) {
        datapoints = this.sortDatapoints(datapoints);
        this.renderDataTable(datapoints);
        this.renderChart(datapoints);
      }

      var { deviceId, deviceName } = config;
      this.device = { name: deviceName, deviceId };
      this.$store.commit("Scanner/ADD_BLE_DEVICE", { deviceId, deviceName });

      // 4. Dis-connect any running device
      if (this.bleDevice && this.bleDevice.isConnected) {
        await this.bleDevice.disconnect();
      }

      // 5. Check if device is validated
      if (!this.checkHolterDeviceLevel(deviceName)) {
        this.loading = false;
        alert(this.$t("error.deviceCodeNotRecognized"));
        return;
      }
      await this.autoRegisterUserAsPatient();
      this.setAccountLevelByDeviceName(deviceName);

      // 6. Re-connect device every 15 seconds
      this.bleDevice = new HeartRateBleDevice(this.handlers);
      this.bleDevice.config = config;
      this.bleDevice.name = deviceName;
      this.bleDevice.bleDevice = this.device;
      this.bleDevice.isConnected = true;

      // We need user interaction to re-connect device
      // Open a confirm dialog
      if (
        !(await this.$refs.PopupConfirm.confirm(
          "",
          `${this.$t("common.reconnectMessage")} ${deviceName}?`
        ))
      ) {
        return;
      }
      this.bleDevice.reconnectDevice();
      this.registerBackgroundMode();
    },
  },
};
</script>

<style lang="scss">
/* Add custom CSS to hide the prev and next buttons */
.v-tabs .v-slide-group__prev,
.v-tabs .v-slide-group__next {
  display: none !important;
}
.info_next_measurement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: bold;
  font-size: 110%;
  margin-bottom: -15px;
}

.info_next_measurement .time {
  font-size: 150%;
  margin-bottom: 0 !important;
  margin-left: 10px;
}
</style>
