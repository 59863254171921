// import moment from "moment";
import {
  BleClient,
  numbersToDataView,
  numberToUUID,
} from "@capacitor-community/bluetooth-le";

import { Capacitor } from "@capacitor/core";

import { sleep } from "./helpers";

var isBleInitialized = false;
// var bleNamePrefix = "PA";
var intervalReconnect = null;

var clearIntervalReconnect = () => {
  clearInterval(intervalReconnect);
  intervalReconnect = null;
  console.log("\n\n\nCLEAR INTERVAL RECONNECT DEVICE SUCCESS");
};

export const forceDisconnectDeviceId = async (deviceId) => {
  try {
    await BleClient.disconnect(deviceId);
  } catch (err) {
    console.log("Discconect error", err);
  }
  clearIntervalReconnect();
};

export class StopPackage {
  view = [];
  constructor() {
    this.view.push(83); // S
    this.view.push(84); // T
    this.view.push(80); // P
  }
  dataView() {
    return numbersToDataView(this.view);
  }
}

export class MeasurementPackage {
  view = [];
  constructor(maxInflatePressure = 180, calibsPerHour = 4) {
    this.view = [];
    this.maxInflatePressure = maxInflatePressure;
    this.maxInflatePressureCode =
      maxInflatePressure == 160
        ? 0
        : maxInflatePressure == 180
        ? 1
        : maxInflatePressure == 220
        ? 2
        : maxInflatePressure == 250
        ? 3
        : 0;
    this.calibsPerHour = calibsPerHour;
    this.isTimeStamp = true; // SET TIME = true => calib with time stamp included

    this.view.push(77); // M
    this.view.push(69); // E
    this.view.push(65); // A

    this.view.push(this.maxInflatePressureCode);
    this.view.push(this.calibsPerHour);

    // var date = moment();
    // var year = date.year();
    // var month = date.month();
    // var day = date.day() - 1;
    // var hour = day.hour();
    // var minute = day.minute();
    // var second = day.second();

    // this.view.push(Math.floor(year / 256));
    // this.view.push(year % 256);
    // this.view.push(month);
    // this.view.push(day);
    // this.view.push(hour);
    // this.view.push(minute);
    // this.view.push(second);
  }

  dataView() {
    return numbersToDataView(this.view);
  }
}

export const scanBleDevice = async (deviceName) => {
  console.log(`>>> Scanned deviceName`, deviceName);
  if (!deviceName || deviceName.length < 4) {
    return alert("Error Name Device not correct!");
  }
  return new Promise((resolve) => {
    BleClient.requestLEScan(
      {
        // namePrefix: deviceName.substring(0, 3) || bleNamePrefix,
        // optionalServices: [numberToUUID(0x1810)],
        services: [numberToUUID(0x1810)],
      },
      (result) => {
        console.log(`>>> Scanned result`, result);
        if (!result) return;
        var { device, localName } = result;
        if (
          (localName && localName.toLowerCase() == deviceName.toLowerCase()) ||
          (device &&
            device.name &&
            device.name.toLowerCase() == deviceName.toLowerCase())
        ) {
          BleClient.stopLEScan();
          return resolve(result);
        }
      }
    );
  });
};

export const stopBleScan = async () => {
  BleClient.stopLEScan();
};

export class HeartRateBleDevice {
  bloodPressureService = numberToUUID(0x1810);
  bloodPressureCharacteristics = numberToUUID(0x2a35);
  intermediateCuffCharacteristics = numberToUUID(0x2a36);
  batteryLevelCharacteristics = numberToUUID(0x2a19);

  config = {};
  bleDevice = {};
  eventHandlers = {};
  name = "";
  isConnected = false;

  constructor(eventHandlers = {}) {
    this.config = {};
    this.bleDevice = {};
    this.eventHandlers = eventHandlers;
    this.name = "";
    this.isConnected = false;
  }

  async connectDevice({ device }) {
    try {
      if (!device || !device.deviceId) return false;

      var handleDeviceDisconnected = async () => {
        if (this.isConnected) {
          this.reconnectDevice();
        }
        this.stopListening();
        var { onDisConnect } = this.eventHandlers;
        if (onDisConnect && typeof onDisConnect == "function") {
          onDisConnect(device);
        }
      };
      if (Capacitor.isNativePlatform()) {
        await BleClient.getDevices([device.deviceId]);
      }
      await BleClient.connect(device.deviceId, handleDeviceDisconnected);
      if (Capacitor.getPlatform() == "android") {
        if (!(await BleClient.isBonded(device.deviceId))) {
          // await BleClient.createBond(device.deviceId);
          BleClient.createBond(device.deviceId);
          await sleep(1200);
        }
      }
      console.log("Connected to device", device);
      this.bleDevice = device;
      this.isConnected = true;
      await sleep(400);
      this.startListeningBattery();
      return true;
    } catch (error) {
      this.bleDevice = {};
      this.isConnected = false;
      console.log("BleError", error.message);
      return false;
    }
  }

  async requestDevice() {
    try {
      var device = await BleClient.requestDevice({
        // namePrefix: bleNamePrefix,
        // optionalServices: [this.bloodPressureService],
        services: [this.bloodPressureService],
      });
      return await this.connectDevice({ device });
    } catch (error) {
      this.bleDevice = {};
      console.log("BleError", error.message);
      return false;
    }
  }

  async disconnect() {
    this.isConnected = false; // <== Extremely important to leave this line here
    await sleep(200);
    try {
      this.stopCalib();
      await BleClient.disconnect(this.bleDevice.deviceId);
    } catch (err) {
      console.log("Discconect error", err);
    }
    clearIntervalReconnect();
    console.log(`device ${this.bleDevice.deviceId} disconnected`);
    this.config = {};
    this.bleDevice = {};
    this.eventHandlers = {};
    this.name = "";
  }

  async applyConfig(config) {
    try {
      this.config = config;
      var { maxInflatePressure, calibsPerHour } = config;
      var measurement = new MeasurementPackage(
        maxInflatePressure,
        calibsPerHour
      );
      return await BleClient.write(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.bloodPressureCharacteristics,
        measurement.dataView()
      );
    } catch (error) {
      console.log("Apply config failed", error.message());
    }
  }

  async stopCalib() {
    var stopMessage = new StopPackage();
    await BleClient.write(
      this.bleDevice.deviceId,
      this.bloodPressureService,
      this.bloodPressureCharacteristics,
      stopMessage.dataView()
    );
  }

  async startListeningBattery() {
    try {
      BleClient.startNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.batteryLevelCharacteristics,
        (value) => {
          var { batteryLevel } = this.parseBatteryLevel(value);
          var { onBatteryLevel } = this.eventHandlers;
          if (onBatteryLevel) {
            onBatteryLevel(batteryLevel);
          }
        }
      );
    } catch (error) {
      console.log("handleBatteryLevel Error", error.message);
    }
  }

  async startListening() {
    try {
      BleClient.startNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.bloodPressureCharacteristics,
        (value) => {
          var { sys, dia, pulse, errorCodeBits16 } =
            this.parseBloodPressure(value);
          var { onBloodPressureValue } = this.eventHandlers;
          if (onBloodPressureValue) {
            onBloodPressureValue({ sys, dia, pulse, errorCodeBits16 });
          }
        }
      );
      BleClient.startNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.intermediateCuffCharacteristics,
        (value) => {
          var { cuffPressure } = this.parseCuffPressure(value);
          var { onCuffPressurevalue } = this.eventHandlers;
          if (onCuffPressurevalue) {
            onCuffPressurevalue(cuffPressure);
          }
        }
      );
    } catch (error) {
      console.log("startListening Error", error.message);
    }
  }

  async stopListening() {
    try {
      BleClient.stopNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.batteryLevelCharacteristics
      );
      BleClient.stopNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.bloodPressureCharacteristics
      );
      BleClient.stopNotifications(
        this.bleDevice.deviceId,
        this.bloodPressureService,
        this.intermediateCuffCharacteristics
      );
    } catch (error) {
      console.log("stopListening Error", error.message);
    }
  }

  async reconnectDevice() {
    if (!this.isConnected) return;
    console.log(
      "\n\n\n\n\n\n\nTry to reconnect device: ",
      this.bleDevice.deviceId
    );
    var isSuccess = false;
    try {
      if (Capacitor.isNativePlatform()) {
        await BleClient.getDevices([this.bleDevice.deviceId]);
      } else {
        await BleClient.requestDevice({
          deviceId: this.bleDevice.deviceId,
          services: [this.bloodPressureService],
        });
      }
      await BleClient.connect(this.bleDevice.deviceId);
      await this.startListening();
      var { onReconnect } = this.eventHandlers;
      if (onReconnect && typeof onReconnect == "function") {
        onReconnect(this.bleDevice.deviceId);
      }
      isSuccess = true;
    } catch (err) {
      console.log("Cannot re-connect device!", err);
    }
    if (!isSuccess) {
      await sleep(15000);
      this.reconnectDevice();
    }
  }

  getDeviceName() {
    return this.bleDevice.name;
  }

  getDeviceId() {
    return this.bleDevice.deviceId;
  }

  parseBloodPressure(value) {
    try {
      value = value.buffer ? value : new DataView(value);
      var byteLengths = Buffer.byteLength(value.buffer);
      console.log("Buffer.byteLength", byteLengths);

      var flag = value.getUint8(0);
      var flag8bit = (flag >> 0).toString(2).padStart(8, "0");

      if (flag8bit[3] == "1") {
        var errorCode = value.getUint16(byteLengths - 2, true);
        return {
          sys: null,
          dia: null,
          mean: null,
          pulse: null,
          errorCodeBits16: parseErrorCode(errorCode),
        };
      }

      return {
        sys: parseUint16(value.getUint16(1, true)),
        dia: parseUint16(value.getUint16(3, true)),
        mean: parseUint16(value.getUint16(5, true)),
        pulse: parseUint16(value.getUint16(7, true)),
      };
    } catch (err) {
      console.log("parseBloodPressure Error", err.message);
      return {};
    }
  }

  parseCuffPressure(value) {
    value = value.buffer ? value : new DataView(value);
    return {
      cuffPressure: parseUint16(value.getUint16(1, true)),
    };
  }

  parseBatteryLevel(value) {
    value = value.buffer ? value : new DataView(value);
    var batteryLevel = value.getUint8(0);
    return {
      batteryLevel,
    };
  }
}

export const initializeBle = async () => {
  if (isBleInitialized) return true;
  try {
    await BleClient.initialize();
    isBleInitialized = true;
    return true;
  } catch (err) {
    console.log("Ble is not available in this device!");
    return false;
  }
};

export const parseErrorCode = (int16) => {
  if (!int16) return [];
  try {
    var bits16 = (int16 >> 0).toString(2).padStart(16, "0");
    return bits16;
  } catch (err) {
    console.log("parseErrorCode", err.message);
    return [];
  }
};

export const parseUint16 = (int16) => {
  try {
    console.log(">>> bits16", (int16 >> 0).toString(2));
    var bits4 = (int16 >> 12).toString(2);
    var bits12 = (int16 >> 0).toString(2).padStart(16, "0").slice(4);
    var val = parseInt(bits12, 2);
    var exp = parseInt(bits4, 2);
    if (int16 > 204) {
      console.log(">>> val", val);
      console.log(">>> exp", exp);
    }
    // if bit4 first digit is 1 => negantive value
    if (bits4[0] == "1") {
      exp = 0 - (parseInt(flipbits(bits4), 2) + 1);
    }
    return val * Math.pow(10, exp);
  } catch {
    return "";
  }
};

var flipbits = (str) =>
  str
    .split("")
    .map((b) => (1 - b).toString())
    .join("");
