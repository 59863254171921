<template>
  <v-dialog v-model="isShow" max-width="420px" min-height="360px">
    <v-card class="custom">
      <v-card-title>
        {{ deviceName ? `${deviceName}` : $t("common.configEpisode") }}
      </v-card-title>
      <span class="block_battery">
        <span>{{ batteryLevel ? `${batteryLevel}%` : "" }}</span>
        <v-icon
          color="white"
          class="ml-1"
          v-if="batteryLevel"
          style="margin-top: -5px"
        >
          mdi-battery{{
            batteryPercent
              ? batteryPercent == 100
                ? ""
                : "-" + batteryPercent
              : "-outline"
          }}
        </v-icon>
      </span>
      <v-card-text class="pt-2 pb-0">
        <v-select
          v-model="data.maxInflatePressure"
          :label="$t('common.maxInflatePressure')"
          filled
          outlined
          required
          hide-details
          :items="pressureOptions"
        ></v-select>
        <v-select
          v-model="data.calibsPerHour"
          :label="$t('common.calibsMethod')"
          filled
          outlined
          required
          hide-details
          :items="calibOptions"
        ></v-select>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn class="pl-5 pr-5" text @click="agree">{{
          $t("common.confirmed")
        }}</v-btn>
        <v-btn v-if="!isNative" class="pl-5 pr-5" text @click="runTest">
          Run test
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    batteryLevel: {
      type: Number,
      default: 0,
    },
    deviceName: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: function () {
        return {
          maxInflatePressure: 180,
          calibsPerHour: 0,
        };
      },
    },
  },
  computed: {
    pressureOptions() {
      return [
        { text: "160 mmHg", value: 160 },
        { text: "180 mmHg", value: 180 },
        { text: "220 mmHg", value: 220 },
        { text: "250 mmHg", value: 250 },
      ];
    },
    calibOptions() {
      var options = [{ value: 0, text: this.$t("common.manualMode") }];
      if (this.$accountLevel == 0) {
        return options;
      }
      if (this.$accountLevel == 1) {
        for (var i of [1, 2, 4]) {
          options.push({
            value: i,
            text: `${i} ${this.$t("common.times")}/${this.$t("common.hour")}`,
          });
        }
      }
      if (this.$accountLevel == 2) {
        for (var j of [1, 2, 3, 4, 5]) {
          options.push({
            value: j,
            text: `${j} ${this.$t("common.times")}/${this.$t("common.hour")}`,
          });
        }
      }
      if (this.$accountLevel == 3) {
        for (var k of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
          options.push({
            value: k,
            text: `${k} ${this.$t("common.times")}/${this.$t("common.hour")}`,
          });
        }
      }
      return options;
    },
  },
  data() {
    return {
      data: {
        maxInflatePressure: 180,
        calibsPerHour: 0,
      },
      isShow: false,
      resolve: null,
      bleDevice: null,
      batteryPercent: null,
      emptyBattery: false,
    };
  },
  mounted() {
    this.batteryPercent = this.roundBatteryPercentage(this.batteryLevel);
  },
  watch: {
    batteryLevel() {
      this.batteryPercent = this.roundBatteryPercentage(this.batteryLevel);
      if (this.batteryLevel && this.batteryLevel < 20) {
        this.emptyBattery = true;
      } else {
        this.emptyBattery = false;
      }
    },
    value() {
      this.data = this.value;
    },
    isShow(val) {
      if (!val) {
        this.cancel();
      }
    },
  },
  methods: {
    confirm(bleDevice) {
      this.bleDevice = bleDevice;
      this.isShow = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    async agree() {
      if (this.emptyBattery) {
        alert(this.$t("error.pinEmptyError"));
        return;
      }
      // TODO: send config signal to bleDevice
      // emit input value to update v-model
      this.$emit("input", this.data);
      // resolve and close the model
      this.resolve(true);
      this.isShow = false;
    },
    async runTest() {
      this.resolve("runTest");
      this.isShow = false;
    },
    async cancel() {
      this.resolve(false);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  .v-card__text {
    font-size: 1rem;
  }
}
.block_battery {
  font-size: 1.2rem;
  position: absolute;
  top: 18px;
  right: 20px;
  display: block;
}
</style>
